<script>
export default {
    
}
</script>

<template>
    <footer class="footer" style="padding: 15px; height: auto">
        <div class="container-fluid">
            <div class="row">
<!--                <div class="col-sm-6">
                    {{new Date().getFullYear()}} &copy; MarketClick.
                </div>-->
                <div class="col-sm-12">
                    <div class="text-sm-end d-none d-sm-block">
<!--                        Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://Pichforest.com/" target="_blank" class="text-reset">Pichforest</a>-->
                      <strong>상호</strong>
                      크리티컬 &nbsp;&nbsp;
                      <strong>대표</strong>
                      손선호 &nbsp;&nbsp;
                      <strong>사업자 등록번호</strong>
                      141-76-00382<br/>
                      <strong>주소</strong>
                      대구광역시 서부 북비산로 260, 703호&nbsp;&nbsp;
                      <strong>고객센터</strong>
                      070-4001-0146 (평일 11:00 ~ 18:00)&nbsp;&nbsp;
                      <strong>email</strong>
                      master@critical.co.kr

                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>