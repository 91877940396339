<script>
import {useMenuItems} from "./menu_new";
import MetisMenu from "metismenujs/dist/metismenujs";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: useMenuItems,
      menuData: null,
    };
  },

  computed: {
    ...mapState(loggedInfoObject),
  },

  mounted: function () {
    //console.log(this.logged_info)
    //console.log(this.menuItems)
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },

  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },

    isMenuOpen(item) {
      if (this.logged_info.business_type === 'clan') {
        console.log("TRUE")

        return item.id === 6 || item.id === 11;
      } else {
        if (item.id === 6) {
          //console.log("menu id : " + item.id)
          if (this.logged_info.macltalk === "N") {
            //console.log("macltalk false")
            return false
          }
        }

        if (item.id === 8) {
          //console.log("menu id : " + item.id)
          if (this.logged_info.alarmtalk === "N") {
            //console.log("alarmtalk false")
            return false
          }
        }

        if (item.id === 15) {
          return this.logged_info.mart_srl === 98;
        }

/*        if (item.id === 101) {
          if (this.logged_info.mart_srl === 98) {
            return true
          } else {
            return false
          }
        }*/
        if (item.id === 111) {
          return this.logged_info.mart_srl === 98;
        }

        if (item.id === 103) {

          if (this.logged_info.mart_srl === 98) {
            return this.logged_info.customevent === 'Y'
          } else {
            return this.logged_info.customevent === 'N'
          }
        }

        if (item.id === 13) {
          return this.logged_info.customevent === 'Y'
        }

        return true
      }
    },
    isSubmenuOpen(item) {
      //console.log("item", item)
      if (item.id === 65) {
        if (this.logged_info.macltalk_graph == 'N') {
          return false
        }
      }
      return true
    }
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="(item, index) in menuItems">
        <div v-if="isMenuOpen(item)" :key="index">
<!--          <li class="menu-title" v-if="item.isTitle" :key="index">
            {{ $t(item.label) }}
          </li>-->
          <li v-if="!item.isTitle && !item.isLayout" :key="index">
            <a
                v-if="hasItems(item)"
                href="javascript:void(0);"
                class="is-parent"
                :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            >
              <feather :type="`${item.icon}`" v-if="item.icon" class="icon nav-icon"></feather>
              <span>{{ $t(item.label) }}</span>
              <!--            <span
                            :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                            v-if="item.badge"
                            >{{ $t(item.badge.text) }}</span>-->
            </a>
            <router-link
                :to="item.link"
                v-if="!hasItems(item)"
                class="side-nav-link-ref"
            >
              <feather :type="`${item.icon}`" v-if="item.icon" class="icon nav-icon"></feather>
              <span>{{ $t(item.label) }}</span>
              <!--            <span
                            :class="`badge rounded-pill ${item.badge.variant} float-end`"
                            v-if="item.badge"
                            >{{ $t(item.badge.text) }}</span>-->
            </router-link>

            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li v-for="(subitem, index) of item.subItems" :key="index">
                <div v-if="isSubmenuOpen(subitem)">
                  <router-link v-if="!hasItems(subitem) && subitem.link !== undefined"
                               :to="subitem.link"

                               class="side-nav-link-ref"
                  >{{ $t(subitem.label) }}
                  </router-link
                  >
                  <a
                      v-if="hasItems(subitem)"
                      class="side-nav-link-a-ref has-arrow"
                      href="javascript:void(0);"
                  >{{ $t(subitem.label) }}</a
                  >
                  <ul
                      v-if="hasItems(subitem)"
                      class="sub-menu mm-collapse"
                      aria-expanded="false"
                  >

                    <li
                        v-for="(subSubitem, index) of subitem.subItems"
                        :key="index"
                    >
                      <router-link
                          :to="subSubitem.link"
                          class="side-nav-link-ref"

                      >{{ $t(subSubitem.label) }}
                      </router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
        </div>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
