export const useMenuItems = [
    {
        id: 1,
        label: 'menuitems.dashboards.text',
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.kanbanboard.text',
        icon: 'trello',
        link: '/'
    },
    {
        id: 15,
        label: 'menuitems.customBarcode.text',
        icon: 'edit',
        link: '/barcode'
    },
    {
        id: 102,
        label: 'menuitems.vertical.text',
        icon: 'image',
        link: '/verticalflyers'
    },
    {
        id: 5,
        label: 'menuitems.flyers.text',
        icon: 'image',
        link: '/flyers'
    },
    {
        id: 13,
        label: 'menuitems.eventlist.text',
        icon: 'trello',
        link: '/eventlist'
    },
    {
        id: 103,
        label: 'menuitems.eventlist.text',
        icon: 'trello',
        link: '/eventoriginlist'
    },
    {
        id: 14,
        label: 'menuitems.bestproduct.text',
        icon: 'trello',
        link: '/bestproduct'
    },
    {
        id: 6,
        label: 'menuitems.maclTalk.text',
        icon: 'message-square',
        subItems: [
            {
                id: 61,
                label: 'menuitems.maclTalk.list.sendTalk',
                link: '/macltalk',
                parentId: 6
            },
            {
                id: 62,
                label: 'menuitems.maclTalk.list.receiver',
                link: '/talk/mngnewmembers',
                parentId: 6
            },
            {
                id: 64,
                label: 'menuitems.maclTalk.list.maclGroupManagement',
                link: '/talk/customer/groups',
                parentId: 6
            },
            {
                id: 63,
                label: 'menuitems.maclTalk.list.maclhistory',
                link: '/talk/history/macltalk',
                parentId: 6
            },
            {
                id: 65,
                label: 'menuitems.maclTalk.list.viewsHistory',
                link: '/talk/viewshistory',
                parentId: 6
            }
        ]
    },

    // 알림톡 메뉴 제거시 아래 객체 주석
    {
        id: 8,
        label: 'menuitems.kakaoTalk.text',
        icon: 'message-square',
        subItems: [
            {
                id: 81,
                label: 'menuitems.kakaoTalk.list.sendKakao',
                link: '/talk/sendtalk',
                parentId: 8
            },
            {
                id: 82,
                label: 'menuitems.kakaoTalk.list.kakaoReceiver',
                link: '/talk/kakaonewmembers',
                parentId: 8
            },
            {
                id: 84,
                label: 'menuitems.kakaoTalk.list.talkHistory',
                link: '/talk/history',
                parentId: 8
            },
            {
                id: 84,
                label: 'menuitems.kakaoTalk.list.pointManagement',
                link: '/talk/mngdeposit',
                parentId: 8
            },
            {
                id: 83,
                label: 'menuitems.kakaoTalk.list.templateManagement',
                link: '/talk/mngTemplete',
                parentId: 8
            },
        ]
    },
    {
        id: 7,
        label: 'menuitems.timeline.list.voc',
        icon: 'volume-2',
        link: '/voc'
    },

    {
        id: 88,
        label: 'menuitems.couponforms.text',
        icon: 'tag',
        link: '/issue_coupon',
        subItems: [
            {
                id: 89,
                label: 'menuitems.couponforms.list.issue',
                link: '/issue_coupon',
                parentId: 88
            },
            {
                id: 90,
                label: 'menuitems.couponforms.list.list',
                link: '/coupon_list',
                parentId: 88
            }
        ]
    },
    {
        id: 12,
        label: 'menuitems.pushmsg.text',
        icon: 'mail',
        link: '/pushmsg'
    },
    {
        id: 11,
        label: 'menuitems.myinfo.text',
        icon: 'user',
        link: '/auth/myinfo'
    },
    {
        id: 101,
        label: 'menuitems.guide.text',
        icon: 'play-circle',
        link: '/guide'
    },
    {
        id: 111,
        // label: 'test',
        icon: 'play-circle',
        link: '/testHtml'
    }
];

